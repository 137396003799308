import React from 'react'
import { useEffect, useState } from 'react';

const MainBody = ({ setDarkMode, DarkMode }) => {

    const [GetData, setGetData] = useState({
        username: '',
        nickname: '',
        followers: '',
        reposcount: '',
        lastRepos: '',
        img: ''
    });

    const { username, nickname, followers, reposcount, lastRepos, img } = GetData;

    const [Form, setForm] = useState(null);

    const fetchAPI = async( user ) => {
        let data = await fetch(`https://api.github.com/users/${ user }`, {
            method: "GET",
        })
                .then( response => response.json() )
                .catch( err => console.log(err) );

        const repos = await fetch(data.repos_url, { method: "GET" })
                .then( response => response.json() )
                .catch( err => console.log(err) );

        while( repos.length != 5 ) {
            repos.pop()
        };

        setGetData({
            ...GetData,
            username: data.name.split(" "),
            nickname: data.login,
            img: data.avatar_url,
            followers: data.followers,
            reposcount: data.public_repos,
            lastRepos: repos
        });

    }

    useEffect(() => {
        fetchAPI("Jopt05");
    }, [])

    const handleSubmit = async(e) => {
        e.preventDefault();
        fetchAPI(Form.user)
    }

    const handleChange = ({ target }) => {
        setForm({...Form, [ target.name ] : target.value })
    }

    return (
        <>
          <div className="bodyContainer fadeIn">
            <div className="textContainer">
            <header className="header">
                <h1 className="headerTitle">
                Git Profiles
                </h1>
                <form className="headerForm" onSubmit={ handleSubmit }>
                <input
                    value={ Form?.user }
                    name="user"
                    className="headerForm__input"
                    onChange={ (e) => handleChange(e) }
                />
                <button
                    className="headerForm__button button"
                >
                    Search
                </button>
                </form>
                <div className="button" onClick={ () => setDarkMode(!DarkMode) }>
                <i className={ `bx bxs-${ DarkMode ? 'sun' : 'moon' }` }></i>
                </div>
            </header>
            <div className="infoContainer">
                <p className="infoP title">
                {
                    ( username?.length > 1 )
                    ? <div> <b> {username[0]} </b> <br></br> {username[1]} </div>
                    : <b>{username[0]}</b>
                }
                </p>
                <p className="infoP">
                <b>Username:</b> { nickname ? nickname : '' }
                </p>
                <p className="infoP">
                <b>Followers:</b> { followers >= 0 ? followers : '' }
                </p>
                <p className="infoP">
                <b>Repository Count:</b> { reposcount ? reposcount : '' }
                </p>
                <div className="repositoriesDiv">
                {/* <a href="#" className="repository button">Repo1</a> */}
                {
                    lastRepos
                    ? lastRepos.map( repo => ( <a href={ repo.html_url } target="_blank" className="repository button"> { repo.name } </a> ) )
                    : ''
                }
                </div>
            </div>
            </div>
        </div>
        <div className="bodyContainer fadeIn">
            <img className="userPhoto" src={ img ? img : '' }></img>
        </div>  
        </>
    )
}

export default MainBody
