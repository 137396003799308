import { useState } from 'react';
import './App.css';
import MainBody from './components/MainBody';

function App() {

  const [DarkMode, setDarkMode] = useState(false);

  return (
    <div className={ `App ${ DarkMode ? 'DarkMode' : '' }` }>
      <div className="backGround"></div>
        <MainBody setDarkMode={ setDarkMode } DarkMode={ DarkMode }/>
    </div>
  );
}

export default App;
